import React from 'react';

import Layout from '../layouts';
import Container from '../components/Container';
import PageTitle from '../components/Subpage/title';
import Placeholder from '../components/Placeholder';

import { MainColour } from '../utils/variables';

import loadComponents from '../components/Loadable';

const Button = loadComponents('button');

export default function NotFoundPage({ location }) {
  const prevPage = location.state && location.state.prevPage;
  return (
    <Layout title="404 Page Not Found" seo={null} prevPage={prevPage}>
      <PageTitle title="404 Page Not Found" />
      <Container>
        <p>It looks like nothing was found at this location.</p>
        <Button
          to="/"
          style={{ width: `350px`, maxWidth: `100%` }}
          fallback={
            <Placeholder
              width="350px"
              height="58px"
              colour={MainColour}
              style={{ borderRadius: `100px` }}
            />
          }
          primary
        >
          Return to Homepage
        </Button>
      </Container>
    </Layout>
  );
}
